import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../common/components/LoadingSpinner/LoadingSpinner';
import { RedirectProps } from './redirect-props';
import { RedirectState } from './redirect-state';
import './Redirect.scss';
import axios, { AxiosError, AxiosResponse } from 'axios';

export const RedirectWrapper = () => {
  const params = useParams();

  return (
    !params || !params.username
      ? <Navigate to={'/error/404'} />
      : <Redirect username={params.username}/>
  );
};

class Redirect extends React.Component<RedirectProps, RedirectState> {
  constructor(props: any) {
    super(props);

    this.state = {
      countdown: 5
    };
  }

  componentDidMount(): void {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ENGINE_URL,
    });

    axiosInstance.get('routes/' + this.props.username).then((response: AxiosResponse) => {
      this.setState({
        redirect: response.data.url,
        userDisplayName: response.data.displayName
      });

      const interval = setInterval(() => {
        const newCountdown = this.state.countdown - 1;
        this.setState({ countdown: newCountdown });
  
        if (newCountdown === 0) {
          window.location.href = response.data.url;
          clearInterval(this.state.interval);
        }
      }, 1000);
  
      this.setState({ interval: interval });
    }, (error: AxiosError) => {
      this.setState({ errorCode: error.response?.status });
    });
  }

  render() {
    return (
      this.state.errorCode
        ? <Navigate to={`/error/${this.state.errorCode}`} />
        :
        <div id="rc-redirect-container">
          {
            !this.state.userDisplayName || !this.state.redirect
              ? <LoadingSpinner id='rc-big-spinner' size={75}/>
              :
              <>
                <div id="rc-message-container">
                  <h1>Rerouting you on behalf of {this.state.userDisplayName}...</h1>
                </div>
                <div id="rc-countdown-container">
                  {
                    this.state.countdown
                      ? <h1>{this.state.countdown}</h1>
                      : <LoadingSpinner />
                  }
                </div>
              </>
          }
        </div>
    );
  }
}
