import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import { ErrorWrapper } from './components/Error/Error';
import { RedirectWrapper } from './components/Redirect/Redirect';
import { Footer } from './components/Footer/Footer';
import { CDN_URL } from './common/app-constants';

export default class App extends React.Component {
  constructor(props: any) {
    super(props);

  }

  render() {  
    return (
      <>
        <div id="ac-main-container">
          <div id="ac-logo-container">
            <img src={(process.env.REACT_APP_CDN_URL || CDN_URL) + 'img/reroute-logo.png'} alt="Reroute logo"/>
          </div>
          <Router>
            <Routes>
              <Route path='/:username' element={<RedirectWrapper />} />
              <Route path='/error/:code' element={<ErrorWrapper />} />
            </Routes>
          </Router>
        </div>
        <Footer />
      </>
    );
  }
}
