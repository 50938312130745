import React from 'react';
import { useParams } from 'react-router-dom';
import { ErrorProps } from './error-props';
import './Error.scss';

export const ErrorWrapper = () => {
  const params = useParams();

  return (
    <Error code={params.code ? parseInt(params.code, 10) : 500}/>
  );
};

class Error extends React.Component<ErrorProps> {
  constructor(props: any) {
    super(props);

    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  getErrorMessage(code: number) {
    let message;

    switch (code) {
    case 404:
      message = 'Sorry, it doesn\'t look like there is anywhere to go for that user. :(';
      break;

    case 409:
      message = 'Sorry, it doesn\'t look like this user is ready to use reroute. :(';
      break;
    
    default:
      message = 'Sorry for the inconvenience. An error has occurred. Please try again later.';
      break;
    }

    return (
      <div id="ec-error-message-container">
        <h1>
          {message}
        </h1>
      </div>
    );
  }

  render() {
    return (
      <div id="ec-error-container">
        {this.getErrorMessage(this.props.code)}
      </div>
    );
  }
}
